import i18n from "@/i18n";
import { markRaw, toRaw } from "vue";
import {
  Plus,
  BottomLeft,
  TopRight,
  Search,
  Download,
} from "@element-plus/icons-vue";
import api from "@/api";
import moment from "moment";
import { getUploadUrl } from "../../utils/tools";
const { t } = i18n.global;
export default {
  tabsLabel: {
    tabA: t("map.acckaiguanbaobiao"),
    tabB: t("map.xiangqingtongji"),
  },
  tableCols: [
    { name: t("report.cph"), filed: "cph" },
    { name: t("report.sbh"), filed: "sbh" },
    { name: t("report.sim"), filed: "sim" },
    { name: t("report.ssgs"), filed: "ssgs" },
    { name: t("report.kssj"), filed: "kssj" },
    { name: t("report.jssj"), filed: "jssj" },
    { name: t("report.acck"), filed: "acck" },
    { name: t("report.accg"), filed: "accg" },
  ],
  tableOps: [
    {
      opType: "btn",
      icon: markRaw(Search),
      name: t("commKey.DETAIL"),
      type: "success",
      click: (row, callback) => {
        callback({ type: "detail", row: row });
      },
    },
  ],
  searchCols: [
    // {
    //   type: "tsel",
    //   ref: "refTree",
    //   key: "devids",
    //   val: [],
    //   title: t("commKey.keyname"),
    //   props: {
    //     icon: "icon",
    //     value: "gps_id",
    //     label: "nodeName",
    //     children: "children",
    //   },
    // },
    {
      type: "car",
      key: "devids",
      data: [],
    },
    {
      type: "sel",
      key: "dateType",
      val: 1,
      title: "",
      data: [
        { name: t("map.jintian"), value: 1 },
        { name: t("map.zuotian"), value: 2 },
        { name: t("map.benzhou"), value: 3 },
        { name: t("map.shangzhou"), value: 4 },
        { name: t("map.benyue"), value: 5 },
        { name: t("map.shangyue"), value: 6 },
        { name: t("map.zidingyi"), value: 7 },
      ],
      change: (val, callback) => {
        callback({ type: "change_time", evt: val });
        // console.log(obj);
      },
    },
    {
      type: "datetime",
      key: "begintime",
      val: moment().format("YYYY-MM-DD 00:00:00"),
      title: t("commKey.STARTDATE"),
    },
    {
      type: "datetime",
      key: "endtime",
      val: moment().format("YYYY-MM-DD 23:59:59"),
      title: t("commKey.ENDDATE"),
    },
    // {
    //   type: "dtr",
    //   key: "times",
    //   val: [
    //     moment().format("YYYY-MM-DD 00:00:00"),
    //     moment().format("YYYY-MM-DD 23:59:59"),
    //   ],
    //   title_s: t("commKey.STARTDATE"),
    //   title_e: t("commKey.ENDDATE"),
    // },

    {
      type: "btn",
      btnType: "success",
      icon: markRaw(Search),
      title: t("commKey.Search"),
      click: (callback) => {
        callback({ type: "search" });
      },
    },

    {
      type: "btn",
      btnType: "",
      icon: markRaw(Download),
      title: t("commKey.EXCEL"),
      click: (callback) => {
        callback({ type: "download" });
      },
    },
    {
      type: "btn",
      btnType: "warning",
      icon: markRaw(Download),
      title: t("commKey.PDF"),
      click: (callback) => {
        callback({ type: "export" });
      },
    },
  ],
  tableDetailCols: [
    { name: t("report.cph"), filed: "cph" },
    { name: t("report.sbh"), filed: "sbh" },
    { name: t("report.sim"), filed: "sim" },
    { name: t("report.ssgs"), filed: "ssgs" },
    { name: t("report.dwsj"), filed: "dwsj" },
    {
      name: t("report.acczt"),
      filed: "acczt",
      render: (row) => {
        return t("report.acc_" + row.acczt) + t("report.gps_" + row.islatlng);
      },
    },
    {
      name: t("report.dswz"),
      filed: "accwz",
      render: (row) => {
        if (row.addr == "") {
          if (row.pLongitude && row.pLatitude)
            callback({
              type: "get_addr",
              row: row,
              fd: "addr",
              lng: row.pLongitude,
              lat: row.pLatitude,
            });
          return (
            "<img src=" +
            require("../../assets/map.png") +
            " style='width:25px'>"
          );
        } else {
          return row.addr;
        }
      },
      click: (row, callback) => {
        if (row.pLongitude && row.pLatitude)
          callback({
            type: "get_addr",
            row: row,
            fd: "addr",
            lng: row.pLongitude,
            lat: row.pLatitude,
          });
        // console.log(row);
      },
    },
  ],
};
